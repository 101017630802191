import React, { useEffect, useState } from "react";
import { apiFetch } from "./snippet/API";

import './css/monitor.css';

export function MonitorPage() {
    const [currentMonthRoom, setCurrentMonthRoom] = useState([])

    useEffect(()=>{
        async function callRoom() {
            const rooms = await apiFetch('/rooms/')
            rooms.forEach(room => {
                //console.log(room, new Date().getMonth())
                setCurrentMonthRoom((prev)=>{
                    return [room, ...prev]
                })
                /*if(new Date().getMonth() == new Date(room.created_at).getMonth()) {
                    //console.log(room)
                }*/
            });
        }

        callRoom()
    },[])
    
    const formatDate = (dateString) => {
        var date = new Date(dateString)
        var year = date.getFullYear();
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var day = ('0' + date.getDate()).slice(-2);
        return year + '.' + month + '.' + day;
    } 

    return (
        <div className="info" style={{overflowY:'scroll'}}>
            <div className="summaries" style={{gridTemplateColumns: 'none', height: '44.5px'}}>
                <div className="summary">
                    <div className="summaryTitle">당월 사용량</div>
                    <div className="summaryContent">{currentMonthRoom.length + '건 (현재까지)'}</div>
                </div>
            </div>
            <div className="monitorList">
                <div className="monitorBar monitorContent">
                    <div>#</div>
                    <div>title</div>
                    <div>sauceflex_id</div>
                    <div style={{textAlign:'end'}}>created_at</div>
                </div>
                {currentMonthRoom.map((room, idx)=>{
                    return  <div className="monitorContent">
                                <div>{idx+1}</div>
                                <a href={"https://player.sauceflex.com/broadcast/"+room.subject}>{room.title}</a>
                                <a href={process.env.REACT_APP_ADDRESS+"/dashboard/"+room.subject}>{room.subject}</a>
                                <div style={{textAlign:'end'}}>{formatDate(room.created_at)}</div>
                            </div>
                })}
            </div>
        </div>
    )
}
